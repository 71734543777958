import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import TextField from '../../text-field';
import MyModal from 'components/modals/modal';
import MyRadio from '../../radio';
import { fieldsName } from './constant';
import { addShippingAddressSchema } from './validation-schema';
import { useSelector } from 'react-redux';
import { customerSupportDataSelector } from 'redux/selectors';
import { selectClientOptionsSelector } from '../../../redux/selectors';
import { responsiveBreakpoints } from 'utils/utils';
import SelectOld from 'components/select/select.old';
import { getStateData } from 'utils/utils';
import { getCityData } from 'utils/utils';
import { getCountryData } from 'utils/utils';

const AddShippingAddressModal = ({
  open,
  setOpen,
  id,
  initialValueForFields,
  title,
  sendApiRequest,
  edit,
  isNotificationAction,
  handleDeclineBtnClick,
  getDropshipAddresses,
  isCustomTitle,
  customTitle,
}) => {
  const supportData = useSelector(customerSupportDataSelector);
  const { mobile, tablet } = responsiveBreakpoints();
  const [status, setStatus] = useState(true);
  const [countryOpt, setCountryOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);

  useEffect(() => {
    if (initialValueForFields && 'active' in initialValueForFields) {
      setStatus(initialValueForFields?.active);
    }
  }, [initialValueForFields]);

  useEffect(() => {
    if (clientOptions.additionalSettings?.csc_dropdown == 1) {
      getCountryDataFunc();
      if (initialValueForFields?.country !== '') {
        let payload = {
          country: initialValueForFields?.country,
        };
        getStateDataFunc(payload);
      }
      if (initialValueForFields?.state !== '') {
        let payload = {
          country: initialValueForFields?.country,
          state: initialValueForFields?.state,
        };
        getCityDataFunc(payload);
      }
    }
  }, []);

  const getCountryDataFunc = async () => {
    let data = await getCountryData();
    setCountryOpt(data?.data?.data ?? []);
  };
  const getStateDataFunc = async (payload) => {
    let data = await getStateData({ payload });
    setStateOpt(data?.data?.data ?? []);
  };
  const getCityDataFunc = async (payload) => {
    const data = await getCityData({ payload });
    setCityOpt(data?.data?.data ?? []);
  };

  const formikRef = useRef(null);
  const {
    locationName,
    shippingID,
    attention,
    zipCode,
    addressLineOne,
    addressLineTwo,
    city,
    state,
  } = fieldsName;

  // Get clientOptions Data
  const clientOptions = useSelector(selectClientOptionsSelector);

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Close
    </Button>
  );

  const decline = (
    <Button
      variant='outlined'
      color='error'
      onClick={() => {
        handleDeclineBtnClick();
        setOpen(false);
      }}
    >
      Decline
    </Button>
  );

  const addAddress = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        saveChangeButtonHandler();
      }}
    >
      {isNotificationAction ? 'Approve' : edit ? 'Save Changes' : 'Add Address'}
    </Button>
  );

  const saveChangeButtonHandler = () => {
    formikRef.current.submitForm();
  };

  const onSubmitFormikHandler = (values) => {
    let payload = {
      retailerId: id,
      name: values.locationName,
      shippingCode: values.shippingID,
      attention: values.attention,
      active: status === 'true' || status === true,
      street: values.addressLineOne,
      street2: values.addressLineTwo,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      country: values.country,
      buyerId: values.buyerId,
      shippingAddressTableId: values.shippingAddressTableId,
    };
    sendApiRequest(
      payload,
      initialValueForFields?.id,
      setOpen,
      getDropshipAddresses
    );
  };

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title={title}
      showCloseIcon
      actions={
        isNotificationAction
          ? [close, decline, addAddress]
          : [close, addAddress]
      }
      sx={
        mobile || tablet
          ? { width: '100%', height: '70vh' }
          : { width: '780px' }
      }
      customTitle={customTitle}
      isCustomTitle={isCustomTitle}
    >
      <Formik
        innerRef={formikRef}
        validateOnMount
        validationSchema={addShippingAddressSchema}
        initialValues={{
          shippingID:
            initialValueForFields?.shippingId ||
            initialValueForFields?.shippingCode,
          locationName: initialValueForFields?.name,
          attention: initialValueForFields?.attention,
          addressLineOne: initialValueForFields?.street,
          addressLineTwo: initialValueForFields?.street2,
          city: initialValueForFields?.city,
          state: initialValueForFields?.state,
          zipCode: initialValueForFields?.zip,
          country: initialValueForFields?.country,
          // ||
          // supportData?.country.find((c) => c.value == 'US')?.value,
          buyerId: initialValueForFields?.buyerId,
          shippingAddressTableId: initialValueForFields?.shippingAddressTableId,
        }}
        enableReinitialize
        onSubmit={onSubmitFormikHandler}
      >
        {({ handleSubmit, ...rest }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant='h2'
                    sx={{
                      borderBottom: '1px solid var(--secondary-border-color)',
                      paddingBottom: '2px',
                    }}
                  >
                    Location Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Location Name'
                    placeholder='Enter Location Name'
                    id={locationName}
                    name={locationName}
                    value={rest.values.locationName}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.locationName &&
                      Boolean(rest.errors.locationName)
                    }
                    helperText={
                      rest.touched.locationName && rest.errors.locationName
                    }
                    sx={
                      initialValueForFields?.shippingAddressName &&
                      initialValueForFields?.shippingAddressName['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Shipping ID / CODE'
                    placeholder='Enter Shipping ID / Code'
                    id={shippingID}
                    name={shippingID}
                    value={rest.values.shippingID}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.shippingID && Boolean(rest.errors.shippingID)
                    }
                    helperText={
                      rest.touched.shippingID && rest.errors.shippingID
                    }
                    sx={
                      initialValueForFields?.shippingAddressCode &&
                      initialValueForFields?.shippingAddressCode['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Attention'
                    placeholder='Enter Attention'
                    id={attention}
                    name={attention}
                    value={rest.values.attention}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.attention && Boolean(rest.errors.attention)
                    }
                    helperText={rest.touched.attention && rest.errors.attention}
                    sx={
                      initialValueForFields?.shippingAddressAttention &&
                      initialValueForFields?.shippingAddressAttention[
                        'color'
                      ] == 'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyRadio
                    onChange={setStatus}
                    value={status}
                    row
                    label='Status'
                    options={[
                      { label: 'Active', value: true },
                      { label: 'Inactive', value: false },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant='h2'
                    sx={{
                      borderBottom: '1px solid var(--secondary-border-color)',
                      paddingBottom: '2px',
                    }}
                  >
                    Shipping Address
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label='Address Line 1'
                    placeholder='Enter Address Line 1'
                    id={addressLineOne}
                    name={addressLineOne}
                    value={rest.values.addressLineOne}
                    onChange={
                      clientOptions.additionalSettings?.csc_dropdown == 0
                        ? (e) => {
                            if (
                              e.target.value.length <=
                              clientOptions.additionalSettings
                                ?.street_character_length
                            ) {
                              rest.setFieldValue(
                                addressLineOne,
                                e.target.value
                              );
                              rest.setFieldError(addressLineOne, '');
                              if (e.target.value.length > 1) {
                                rest.setFieldTouched(addressLineOne, true);
                              }
                            } else {
                              rest.setFieldError(
                                addressLineOne,
                                `Address Line 1 cannot exceed ${clientOptions.additionalSettings?.street_character_length} characters`
                              );
                            }
                          }
                        : rest?.handleChange
                    }
                    error={
                      rest.touched.addressLineOne &&
                      Boolean(rest.errors.addressLineOne)
                    }
                    helperText={
                      rest.touched.addressLineOne && rest.errors.addressLineOne
                    }
                    sx={
                      initialValueForFields?.shippingAddressStreet &&
                      initialValueForFields?.shippingAddressStreet['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Address Line 2'
                    placeholder='Enter Address 2'
                    id={addressLineTwo}
                    name={addressLineTwo}
                    value={rest.values.addressLineTwo}
                    onChange={
                      clientOptions.additionalSettings?.csc_dropdown == 0
                        ? (e) => {
                            if (
                              e.target.value.length <=
                              clientOptions.additionalSettings
                                ?.street2_character_length
                            ) {
                              rest.setFieldValue(
                                addressLineTwo,
                                e.target.value
                              );
                              rest.setFieldError(addressLineTwo, '');
                              rest.setFieldTouched(addressLineTwo, true);
                            } else {
                              rest.setFieldError(
                                addressLineTwo,
                                `Address Line 2 cannot exceed ${clientOptions.additionalSettings?.street2_character_length} characters`
                              );
                            }
                          }
                        : rest?.handleChange
                    }
                    error={
                      rest.touched.addressLineTwo &&
                      Boolean(rest.errors.addressLineTwo)
                    }
                    helperText={
                      rest.touched.addressLineTwo && rest.errors.addressLineTwo
                    }
                    sx={
                      initialValueForFields?.shippingAddressStreet2 &&
                      initialValueForFields?.shippingAddressStreet2['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid
                  container
                  sx={{ padding: '16px 0px 23px 20px' }}
                  columnSpacing={3}
                  rowSpacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                      <TextField
                        label='City'
                        placeholder='Enter City'
                        id={city}
                        name={city}
                        value={rest.values.city}
                        onChange={(e) => {
                          if (
                            e.target.value.length <=
                            clientOptions.additionalSettings
                              ?.city_character_length
                          ) {
                            rest.setFieldValue(city, e.target.value);
                            rest.setFieldError(city, '');
                            rest.setFieldTouched(city, true);
                          } else {
                            rest.setFieldError(
                              city,
                              `City name cannot exceed ${clientOptions.additionalSettings?.city_character_length} characters`
                            );
                          }
                        }}
                        error={rest.touched.city && Boolean(rest.errors.city)}
                        helperText={rest.touched.city && rest.errors.city}
                        sx={
                          initialValueForFields?.shippingAddressCity &&
                          initialValueForFields?.shippingAddressCity['color'] ==
                            'red'
                            ? { color: 'red' }
                            : {}
                        }
                      />
                    ) : (
                      <SelectOld
                        sx={{
                          maxWidth: '350px',
                        }}
                        label='Country'
                        placeholder='Enter Country'
                        id='country'
                        name='country'
                        value={{ label: rest?.values?.country }}
                        showOptionLabel
                        setFormikValue={(id, option) => {
                          if (rest.values['country'] !== option?.value) {
                            rest.setFieldValue(id, option?.value);
                            let payload = {
                              country: option?.value,
                            };
                            getStateDataFunc(payload);
                          }
                        }}
                        error={
                          rest?.touched?.country &&
                          Boolean(rest?.errors?.country)
                        }
                        helperText={
                          rest?.touched?.country && rest?.errors?.country
                        }
                        allowRemoveIcon={false}
                        options={(() => {
                          var arr = [];
                          countryOpt.forEach((option) => {
                            arr.push({
                              label: option.name,
                              value: option.code,
                            });
                          });
                          return arr;
                        })()}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                      <TextField
                        label='State / Province'
                        placeholder='Enter State / Province'
                        id={state}
                        name={state}
                        value={rest.values.state}
                        onChange={(e) => {
                          if (
                            e.target.value.length <=
                            (clientOptions.additionalSettings?.state_length == 0
                              ? clientOptions.additionalSettings
                                  ?.state_character_length
                              : clientOptions.additionalSettings?.state_length)
                          ) {
                            rest.setFieldValue(state, e.target.value);
                            rest.setFieldError(state, '');
                            rest.setFieldTouched(state, true);
                          } else {
                            rest.setFieldError(
                              state,
                              `State / Province name cannot exceed ${
                                clientOptions.additionalSettings
                                  ?.state_length == 0
                                  ? clientOptions.additionalSettings
                                      ?.state_character_length
                                  : clientOptions.additionalSettings
                                      ?.state_length
                              } characters`
                            );
                          }
                        }}
                        error={rest.touched.state && Boolean(rest.errors.state)}
                        helperText={rest.touched.state && rest.errors.state}
                        inputProps={{
                          maxLength:
                            clientOptions?.additionalSettings?.state_length !=
                              0 &&
                            clientOptions?.additionalSettings?.state_length,
                        }}
                        sx={
                          initialValueForFields?.shippingAddressState &&
                          initialValueForFields?.shippingAddressState[
                            'color'
                          ] == 'red'
                            ? { color: 'red' }
                            : {}
                        }
                      />
                    ) : (
                      <SelectOld
                        sx={{
                          maxWidth: '350px',
                        }}
                        label='State / Province'
                        placeholder='Select State / Province'
                        id={state}
                        name={state}
                        value={{ label: rest?.values?.state }}
                        showOptionLabel
                        setFormikValue={(id, option) => {
                          if (rest.values[state] !== option?.value) {
                            rest.setFieldValue(id, option?.value);
                            let payload = {
                              country: rest.values['country'],
                              state: option?.value,
                            };
                            getCityDataFunc(payload);
                          }
                        }}
                        error={
                          rest?.touched?.state && Boolean(rest?.errors?.state)
                        }
                        helperText={rest?.touched?.state && rest?.errors?.state}
                        allowRemoveIcon={false}
                        options={(() => {
                          var arr = [];
                          stateOpt.forEach((option) => {
                            arr.push({
                              label: option.name,
                              value: option.code,
                            });
                          });
                          return arr;
                        })()}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label='Zip / Postal Code'
                      placeholder='Enter Zip / Postal Code'
                      id={zipCode}
                      name={zipCode}
                      value={rest.values.zipCode}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.zipCode && Boolean(rest.errors.zipCode)
                      }
                      helperText={rest.touched.zipCode && rest.errors.zipCode}
                      sx={
                        initialValueForFields?.shippingAddressZip &&
                        initialValueForFields?.shippingAddressZip['color'] ==
                          'red'
                          ? { color: 'red' }
                          : {}
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                      <TextField
                        allowRemoveIcon={false}
                        required
                        id='country'
                        name='country'
                        label='Country'
                        placeholder='Enter Country'
                        value={rest?.values?.country}
                        onChange={(e) => {
                          if (
                            e.target.value.length <=
                            (clientOptions.additionalSettings?.country_length ==
                            0
                              ? clientOptions.additionalSettings
                                  ?.country_character_length
                              : clientOptions.additionalSettings
                                  ?.country_length)
                          ) {
                            rest.setFieldValue('country', e.target.value);
                            rest.setFieldError('country', '');
                            if (e.target.value.length > 1) {
                              rest.setFieldTouched('country', true);
                            }
                          } else {
                            rest.setFieldError(
                              'country',
                              `Country name cannot exceed ${
                                clientOptions.additionalSettings
                                  ?.country_length == 0
                                  ? clientOptions.additionalSettings
                                      ?.country_character_length
                                  : clientOptions.additionalSettings
                                      ?.country_length
                              } characters`
                            );
                          }
                        }}
                        error={
                          rest?.touched?.country &&
                          Boolean(rest?.errors?.country)
                        }
                        helperText={
                          rest?.touched?.country && rest?.errors?.country
                        }
                        inputProps={{
                          maxLength:
                            clientOptions?.additionalSettings?.country_length !=
                              0 &&
                            clientOptions?.additionalSettings?.country_length,
                        }}
                        sx={
                          initialValueForFields?.shippingAddressCountry &&
                          initialValueForFields?.shippingAddressCountry[
                            'color'
                          ] == 'red'
                            ? { color: 'red' }
                            : {}
                        }
                      />
                    ) : (
                      <SelectOld
                        sx={{
                          maxWidth: '350px',
                        }}
                        label='City'
                        id={city}
                        name={city}
                        placeholder='Enter City'
                        value={{ label: rest?.values?.city }}
                        showOptionLabel
                        setFormikValue={(id, option) => {
                          rest.setFieldValue(id, option?.value);
                        }}
                        error={
                          rest?.touched?.city && Boolean(rest?.errors?.city)
                        }
                        helperText={rest?.touched?.city && rest?.errors?.city}
                        allowRemoveIcon={false}
                        options={(() => {
                          var arr = [];
                          cityOpt.forEach((option) => {
                            arr.push({
                              label: option.name,
                              value: option.name,
                            });
                          });
                          return arr;
                        })()}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </MyModal>
  );
};

AddShippingAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialValueForFields: PropTypes.object,
  title: PropTypes.string.isRequired,
  sendApiRequest: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  dropship: PropTypes.bool,
  isNotificationAction: PropTypes.bool,
  handleDeclineBtnClick: PropTypes.func,
  getDropshipAddresses: PropTypes.func,
  isCustomTitle: PropTypes.bool,
  customTitle: PropTypes.node,
};

AddShippingAddressModal.defaultProps = {
  initialValueForFields: {},
  edit: false,
  dropship: false,
  isNotificationAction: false,
  handleDeclineBtnClick: () => {},
  getDropshipAddresses: () => {},
  isCustomTitle: false,
  customTitle: <></>,
};

export default AddShippingAddressModal;

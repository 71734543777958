import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api/index';
import { showSnackbar } from '../../components/snackbar/actions';
import { showInfoModal } from 'components/info-modal/actions';
import { snackbarTypes, infoModalTypes } from '../../constants';

const namespace = 'pages/customers/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const GET_ORDERS = `${namespace}GET_ORDERS`;
export const GET_ERP_ERROR_LOGS = `${namespace}GET_ERP_ERROR_LOGS`;
export const GET_ORDERS_DETAILS = `${namespace}GET_ORDERS_DETAILS`;
export const GET_ORDERS_SEARCH_DETAILS = `${namespace}GET_ORDERS_SEARCH_DETAILS`;
export const GET_BATCH_ORDERS = `${namespace}GET_BATCH_ORDERS`;
export const PROCESS_REPROCESS = `${namespace}PROCESS_REPROCESS`;
export const DOWNLOAD_REPORT_BATCHORDER = `${namespace}DOWNLOAD_REPORT_BATCHORDER`;
export const ORDER_DETAILS_PAGE = `${namespace}ORDER_DETAILS_PAGE`;
export const GET_WORKSHEET_TABS = `${namespace}GET_WORKSHEET_TABS`;
export const GET_CHECKOUT_QUESTIONS = `${namespace}GET_CHECKOUT_QUESTIONS`;
export const GET_HANGTAGS = `${namespace}GET_HANGTAGS`;
export const GET_COUNTRY_OPTIONS = `${namespace}GET_COUNTRY_OPTIONS`;
export const GET_ORDER_ACTIONS = `${namespace}GET_ORDER_ACTIONS`;
export const EDIT_SHIPPING_ADDRESS = `${namespace}EDIT_SHIPPING_ADDRESS`;
export const PROCESS_ORDER_ACTION = `${namespace}PROCESS_ORDER_ACTION`;
export const PROCESS_PAYMENT_ACTION = `${namespace}PROCESS_PAYMENT_ACTION`;
export const PAYMENT_LOGS = `${namespace}PAYMENT_LOGS`;
export const COLLECT_PAYMENT = `${namespace}COLLECT_PAYMENT`;
export const EXPORT_TO_EXCEL = `${namespace}EXPORT_TO_EXCEL`;
export const SEND_PAYMENT_REQUEST = `${namespace}SEND_PAYMENT_REQUEST`;
export const UPDATE_ORDER_ON_ELASTIC = `${namespace}UPDATE_ORDER_ON_ELASTIC`;
export const SYNC_REPORT_DATA = `${namespace}SYNC_REPORT_DATA`;
export const SYNC_ORDER_DATA_ON_CLICK_HOUSE = `${namespace}SYNC_ORDER_DATA_ON_CLICK_HOUSE`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const getOrders = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_ORDERS`));
    let apiString = '';
    if (payload?.previousFilters) {
      apiString = `${apiEndPoints.orderListing}/${payload?.dataType}?&filterValue=${payload?.statuses}&filterTable=${payload?.filterTable}&filterType=${payload?.filterType}&iDisplayLength=${payload?.displayLength}&iDisplayStart=${payload?.displayStart}&loadBuyerOrders=${payload?.loadBuyerOrders}&mode=${payload?.mode}&navType=order&templateId=${payload?.templateId}&orderListType=${payload?.orderListType}&fltrOrder=${payload?.filterBy}&previousFilters=${payload?.previousFilters}`;
    } else {
      apiString = `${apiEndPoints.orderListing}/${payload?.dataType}?&filterValue=${payload?.statuses}&filterTable=${payload?.filterTable}&filterType=${payload?.filterType}&iDisplayLength=${payload?.displayLength}&iDisplayStart=${payload?.displayStart}&loadBuyerOrders=${payload?.loadBuyerOrders}&mode=${payload?.mode}&navType=order&templateId=${payload?.templateId}&orderListType=${payload?.orderListType}&fltrOrder=${payload?.filterBy}`;
    }
    const response = await api.get(apiString);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_ORDERS));
  }
};
//  order search by order or products
export const searchByOrdersOrProductsListing =
  (payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(`GET_ORDERS_SEARCH_DETAILS`));

      const response = await api.get(
        `${apiEndPoints.orderListing}/${
          payload?.dataType
        }?filterValue=${encodeURIComponent(
          payload?.globalSearchValue
        )}&filterTable=${
          payload?.searchByOrdersOrProducts
        }&filterType=searchFilter&iDisplayLength=${
          payload?.displayLength
        }&iDisplayStart=${
          payload?.displayStart
        }&navType=order&loadBuyerOrders=${payload?.loadBuyerOrders}&mode=${
          payload?.mode
        }&fltrOrder=${payload?.filterBy}&previousFilters=${
          payload?.previousFilters
        }`
      );
      const { data } = response;

      if (response.status === 200 || data.status) {
        return data;
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_ORDERS_SEARCH_DETAILS));
    }
  };
//  order search by order or products
export const searchOrSortByColumns = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_ORDERS_SEARCH_DETAILS`));

    const response = await api.get(
      `${apiEndPoints.orderListing}/${
        payload?.dataType
      }?filterValue=${encodeURIComponent(payload.filterValue)}&filterTable=${
        payload.filterTable
      }&filterType=${payload.filterType}&iDisplayLength=${
        payload.displayLength
      }&iDisplayStart=${payload.displayStart}&navType=order&loadBuyerOrders=${
        payload.loadBuyerOrders
      }&mode=${payload.mode}&fltrOrder=${payload.filterBy}&orderListType=${
        payload.orderListType
      }&previousFilters=${encodeURIComponent(payload?.previousFilters ?? '')}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_ORDERS_SEARCH_DETAILS));
  }
};
//  erp error logs modal get order details
export const getErpErrorLogs = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_ERP_ERROR_LOGS`));

    const response = await api.get(apiEndPoints.erpErrorLogs(payload));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_ERP_ERROR_LOGS));
  }
};
//  order history modal get order details
export const getOrderDetails = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_ORDERS_DETAILS`));

    const response = await api.get(apiEndPoints.orderDetails(payload));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_ORDERS_DETAILS));
  }
};
//  get Batch orders
export const getBatchOrders = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_BATCH_ORDERS`));

    const response = await api.get(apiEndPoints.batchOrdersData);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_BATCH_ORDERS));
  }
};
//  Process and Re Process batch orders
export const processReProcess = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`PROCESS_REPROCESS`));

    const response = await api.post(
      apiEndPoints.batchOrdersDataProcess(payload)
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(PROCESS_REPROCESS));
  }
};
//  download report batchorder
export const dowloadBatchOrderReport = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(`DOWNLOAD_REPORT_BATCHORDER`));

    const response = await api.post(apiEndPoints.generateBatchSpreadsheet);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DOWNLOAD_REPORT_BATCHORDER));
  }
};
//  get complete order details for oder details page
export const getOrderDetailsPage = (id, type) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`ORDER_DETAILS_PAGE`));

    const response = await api.get(apiEndPoints.orderDetailsPage(id, type));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ORDER_DETAILS_PAGE));
  }
};
//  get complete worksheet tabs
export const getWorkSheetTabsApi = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_WORKSHEET_TABS`));

    const response = await api.get(apiEndPoints.displayWorksheetTabs(id));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_WORKSHEET_TABS));
  }
};
//  get complete checkoutquestions tabs
export const getCheckoutQuestionsApi = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_CHECKOUT_QUESTIONS`));

    const response = await api.get(apiEndPoints.getCheckoutQuestion(id));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_CHECKOUT_QUESTIONS));
  }
};
//  get complete HangTags
export const getHangTagsApi = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_HANGTAGS`));

    const response = await api.get(apiEndPoints.worksheetHangtags(id));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_HANGTAGS));
  }
};
//  get Country Options

export const getCustomerSupportData = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_COUNTRY_OPTIONS));
    const response = await api.get(apiEndPoints.customerSupportData);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return response;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_COUNTRY_OPTIONS));
  }
};
//  fetch order actions data
export const getOrderActionsData = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_ORDER_ACTIONS));

    const response = await api.get(apiEndPoints.orderActions(id));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_ORDER_ACTIONS));
  }
};
//  edit  Shipping Address
export const editShippingAddress = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_SHIPPING_ADDRESS));
    const response = await api.put(
      apiEndPoints.editShippingRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_SHIPPING_ADDRESS));
  }
};
//  Process order action
export const processOrderActionApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(PROCESS_ORDER_ACTION));
    const response = await api.post(apiEndPoints.processOrder, payload);
    if (response.status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            response?.data?.message ?? 'Order Status Updated Successfully',
          type: snackbarTypes.SUCCESS,
        })
      );
      return response;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response.message,
        type: snackbarTypes.ERROR,
      })
    );
    return response;
  } catch (error) {
    dispatch(setApiError(error));
    return error;
  } finally {
    dispatch(removeActionLoader(PROCESS_ORDER_ACTION));
  }
};
//  Process Payment action
export const processPaymentActionApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(PROCESS_ORDER_ACTION));
    const response = await api.post(apiEndPoints.processPaymentOrder, payload);

    if (response.status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            response.data.response_body.message ??
            'Payment Status Updated Successfully',
          type: snackbarTypes.SUCCESS,
        })
      );
      return response;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response.message,
        type: snackbarTypes.ERROR,
      })
    );
    return response;
  } catch (error) {
    dispatch(setApiError(error));
    return error;
  } finally {
    dispatch(removeActionLoader(PROCESS_PAYMENT_ACTION));
  }
};
//  process collect and authorize payment
export const collectAuthorizePaymentAPI = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(COLLECT_PAYMENT));
    const { data } = await api.post(apiEndPoints.collectPayment, payload);
    if (data?.status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            data?.message ?? 'Payment Status Updated Successfully',
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showInfoModal({
        messageTitle:
          data?.data?.resptext ?? data?.message ?? 'Failed to process payment',
        message: null,
        type: infoModalTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return error;
  } finally {
    dispatch(removeActionLoader(COLLECT_PAYMENT));
  }
};
//  get Payment Logs
export const getPaymentLogsApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`PAYMENT_LOGS`));

    const response = await api.get(
      `${apiEndPoints.paymentLogs}?iDisplayLength=${payload.limit}&iDisplayStart=${payload.pageNumber}&sortDirection=${payload.sortDirection}&sortColumn=${payload.sortColumn}&filters=${payload.filter}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(PAYMENT_LOGS));
  }
};
//  Export To Excel
export const exportToExcelApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`EXPORT_TO_EXCEL`));

    const response = await api.get(apiEndPoints.exportOrderListSpreadsheet);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EXPORT_TO_EXCEL));
  }
};

// send payment request email
export const sendPaymentRequestEmail = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(SEND_PAYMENT_REQUEST));
    const response = await api.post(
      apiEndPoints.sendPaymentRequestEmail,
      payload
    );

    if (response.status) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            response?.data?.message ?? 'Payment request sent Successfully',
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response?.data?.message ?? 'Something went wrong.',
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return error;
  } finally {
    dispatch(removeActionLoader(SEND_PAYMENT_REQUEST));
  }
};

export const updateOrderOnElasticAction = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_ORDER_ON_ELASTIC));

    const response = await api.get(
      `${apiEndPoints.updateOrderOnElastic}/${payload?.worksheetCollectionId}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_ORDER_ON_ELASTIC));
  }
};

export const syncReportData = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(SYNC_REPORT_DATA));

    const response = await api.post(
      apiEndPoints.syncReportDataOnClickHouse,
      payload
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SYNC_REPORT_DATA));
  }
};

export const syncOrderDataOnClickHouse = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(SYNC_ORDER_DATA_ON_CLICK_HOUSE));
    const response = await api.get(
      `${apiEndPoints.syncOrderDataOnClickHouse}/${payload?.worksheetCollectionId}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SYNC_ORDER_DATA_ON_CLICK_HOUSE));
  }
};
